button,
input,
select,
textarea {
  border: 0;
  background: none;
  padding: 0;
}

*:focus {
  outline: 0 !important;
}

*::-moz-focus-inner,
*::-moz-focus-outer {
  outline: 0 !important;
  border: 0 !important;
}

button,
img {
  display: block;
}

ul {
  list-style: none;
  padding-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
  text-align: center;
}

body {
  background: white;
}

p {
  margin-bottom: 0;
}

// fix Bootstrap Reboot breaking CodeMirror CSS with margin
pre {
  margin-bottom: 0;
}

body,
html {
  height: 100%;
}
