@import '../css/fantasque/stylesheet.css';
@import '../css/base';

//// main.html

// https://css-tricks.com/couple-takes-sticky-footer/#article-header-id-3
html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.header {
  margin: map-get($spacers, 5);

  .links {
    & > * {
      margin-right: 1.4rem;

      img {
        margin-right: -2px;
      }
    }
  }
}

.footer {
  margin-top: map-get($spacers, 5);
  padding: map-get($spacers, 3);
  color: var(--grey-1);
  border-top: $border-2;
}

.inactive {
  color: grey;
}

table._battles {
  ._robot {
    background: var(--grey-4);
  }

  ._robot._winner {
    background: #e3e5ee;
  }

  ._rating {
    //background: hsl(0, 0%, 91%);
    //padding: 3px;
    color: grey;

    img {
      display: inline;
      width: 13px;
      vertical-align: middle;
    }
  }

  ._errored {
    img {
      width: 13px;
    }
  }
}

._table {
  display: inline-block;

  ._header, ._footer {
    justify-content: space-between;
    border: $border-2;
    display: flex;

    & > * {
      padding: map-get($spacers, 1) map-get($spacers, 3);
    }
  }

  ._header {
    margin-bottom: -2px;
    background: var(--grey-3);
    justify-content: space-between;
  }

  ._footer {
    margin-top: -2px;
  }

  ._body {
    width: 100%;
  }
}

.actions {
  white-space: nowrap;

  & > * {
    margin-right: map-get($spacers, 4);
  }
}

.bio {
  background: var(--grey-4);
  padding: map-get($spacers, 3);

  ul {
    list-style: unset;
    padding-left: 2rem;
  }

  & > * {
    margin-bottom: 1rem;
  }
  
  & > *:last-of-type {
    margin-bottom: 0;
  }
}

form textarea {
  width: 100%;
  height: auto;
  max-width: 400px;
}

//// tables

tbody {
  white-space: nowrap;

  tr {
    border: $border-2;

    td {
      padding: map-get($spacers, 1) map-get($spacers, 3);
    }

    td:last-child {
      border-right: 0;
    }
  }
}

//// index.html

.index {
  .overview {
    .rules {
      margin-left: map-get($spacers, 3);
      margin-top: map-get($spacers, 2);
      margin-bottom: map-get($spacers, 2);
    }
  }

  img._demo {
    width: 400px;
  }
}

//// viewCode.html
.view-code {
  textarea {
    width: 100%;
    height: 70vh;
    border: $border-2;
    padding: map-get($spacers, 3);
  }
}


//// robot/battle.html
.battle {
  .winner {
    position: relative;
    padding: 0 1rem;
    display: inline-block;
    background: var(--grey-3);
  }
}

//// board/index.html
.boards {
  h2 {
    margin-top: 3rem;
  }
}


@include media-breakpoint-down(md) {
  body {
    font-size: 1rem;
  }

  .index {
    img._demo {
      width: 60vw;
    }
  }
}

@include media-breakpoint-down(sm) {
  .header {
    margin: map-get($spacers, 3);
    flex-direction: column;

    .links {
      margin-top: map-get($spacers, 3);
      flex-direction: column;
    }
  }

  .main {
    margin: map-get($spacers, 5) 0 0;

    h1 {
      margin-bottom: map-get($spacers, 3);
    }
  }

  .footer {
    font-size: 0.8rem;
    flex-direction: column;
    padding: map-get($spacers, 3);
  }

  tbody {
    tr {
      td {
        padding: map-get($spacers, 1) map-get($spacers, 2) !important;
      }
    }
  }

  .battle {
    ._app-root ._grid-viewer {
      flex-direction: column;
      align-items: center;

      ._grid-viewer-main {
        margin-bottom: map-get($spacers, 3);
        width: 90%;
      }

      ._inspector {
        width: 90%;
      }
    }
  }
}

