$font-size-base: 1.2rem;
//$line-height-base: 1.2;
$code-font: Fantasque, monospace; 
$font-family-base: $code-font;

:root {
  --white: white;
  --black: black;
  --red: #f392b1;
  --blue: #578eb3;
  --orange: #ef8509;
  --dark-blue: #39acff;
  --light-red: #fcdee7;
  --light-blue: #cae9ff;
  --bright-red: #dc3545;
  --bright-blue: #007bff;
  --grey-1: #636363;
  --grey-2: #c7c7c7;
  --grey-3: #f0f0f0;
  --grey-4: #f7f7f7;
  --gold: #eff7bb;
  --is-dark-theme: 0;
}

$border-1: 2px solid var(--grey-2);
$border-2: 2px solid var(--grey-3);
$border-3: 1px solid var(--grey-3);

$h2-font-size: $font-size-base * 1.75;

@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';

:root {
  --bs-font-monospace: Fantasque, monospace;
}

$spacers: map-merge((
        6: 5rem,
        7: 10rem,
), $spacers);

.d-flex {
  display: flex;
}

.font-italic {
  font-style: italic;
}

.font-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

@import './fixes';

@each $color, $value in $colors {
  .text-#{$color} {
    color: map-get($colors, $color) !important;
  }
}

//// global

body {
  background: var(--white);
  color: var(--black);
}

button,
input,
select,
textarea {
  color: var(--black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: left;
  font-weight: normal !important;
}

h1 {
  margin-bottom: map-get($spacers, 4);
}

h2 {
  margin-bottom: map-get($spacers, 3);
}

input,
select,
textarea {
  padding: 0 map-get($spacers, 1);
  border: $border-1;
}

a {
  color: var(--blue) !important;
  text-decoration: none !important;
}

$icon-size: 18px;

img {
  width: $icon-size;
  display: inline-block;
}

button {
  color: var(--blue);
  font-weight: bold;
}

.button {
  // use box-shadow instead of border so that the height isn't increased
  box-shadow: inset 0 0 0 2px var(--blue);

  color: var(--blue);
  background: none;
  padding: 0 0.5rem;

  &:disabled {
    box-shadow: inset 0 0 0 2px var(--grey-1);
    color: var(--grey-1);
  }
}

.error {
  color: var(--bright-red) !important;
}

.error-wrapper {
  background: var(--grey-3);
}

.internal-error {
  color: var(--bright-red) !important;
}

.text-red {
  color: var(--bright-red);
}

.text-blue {
  color: var(--bright-blue);
}

.text-grey {
  color: var(--grey-1);
}
